const VISION_SEARCH_API_KEY = process.env.NEXT_PUBLIC_VISION_SEARCH_API_KEY

export const configureOptions = (method: string): RequestInit => {
  return {
    method,
    headers: {
      Accept: 'application/json',
      'x-api-key': VISION_SEARCH_API_KEY,
    },
  }
}

export async function fetchWithRetry(url: string, options?: RequestInit) {
  try {
    return await fetch(url, options).then((res) => {
      return res.json()
    })
  } catch (error) {
    console.error(error)
  }

  return null
}

export function dataURItoBuffer(dataURI: string) {
  // Extract the Base64-encoded data from the URI
  const base64Data = dataURI.split(',')[1]

  return Buffer.from(base64Data, 'base64')
}
