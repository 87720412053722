import React from 'react'
import classNames from 'classnames'
import { Image } from '~/components/Image'
import { getImageSrc, getImageSrcSet } from '~/helpers'
import styles from './HeroBanner.module.css'
import { BackgroundImageProps } from './HeroBanner.types'

const HeroBannerBackgroundImage = ({
  desktop,
  tablet,
  mobile,
  isCurvedImage,
}: BackgroundImageProps) => {
  const mobileImg = mobile || tablet || desktop
  const tabletImg = tablet || desktop || mobile
  const desktopImg = desktop || tablet || mobile

  return (
    desktopImg?.src && (
      <Image
        sizes='(min-width: 1024px) 100vw, 0px'
        className={classNames(
          'w-full h-full',
          isCurvedImage && styles.imageContainer
        )}
        src={getImageSrc(desktopImg.src, '')}
        addSrcSet={false}
        alt={desktopImg.altText}
        width={desktopImg.width}
        height={desktopImg.height}
        priority
        style={{ objectFit: 'cover' }}
        sources={
          <>
            <source
              srcSet={getImageSrcSet(mobileImg?.src, [
                {
                  intrinsicImageSize: '800',
                  width: '800',
                },
              ])}
              media='(max-width: 768px)'
            />
            <source
              srcSet={getImageSrcSet(tabletImg?.src, [
                {
                  intrinsicImageSize: '1100',
                  width: '1100',
                },
              ])}
              media='(max-width: 1024px)'
            />
          </>
        }
      />
    )
  )
}

export default HeroBannerBackgroundImage
