import React, { useMemo } from 'react'
import classNames from 'classnames'
import { BaseMoney } from 'shared-types/src/commercetools'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useFormatPrice } from '~/hooks'
import { SalePriceSavePercent } from '~/types'
import styles from './SalePrice.module.scss'
import { SalePriceProps } from './SalePrice.types'

const getFormattedSavePrice = (price, formatPrice) => {
  if (!price) {
    return null
  }
  if ((price.save as SalePriceSavePercent)?.type === 'percent') {
    return {
      type: 'percent',
      value: `${(price.save as SalePriceSavePercent).value || 0}%`,
    }
  }
  return {
    type: (price.save as BaseMoney)?.type,
    value: formatPrice(price.save as BaseMoney),
  }
}

const getFormattedPercent = (
  percent: SalePriceSavePercent,
  shouldTruncate = false
) => {
  if (!percent.value) {
    return ''
  }

  const value = `${percent.value}%`
  return shouldTruncate ? value.replace('.00', '') : value
}

const getFormattedPrice = (price: string, shouldTruncate = false) => {
  return shouldTruncate ? price.replace('.00', '') : price
}

const SalePriceWithSaveUpto = ({
  price,
  regularPriceClassName,
  savePriceClassName,
  wasText = 'Now',
  saveText = 'Save',
  saveUpToButtonStyle,
  wrapperClassName,
  regularPriceTypographyVariant,
  savePriceTypographyVariant,
  truncateDecimalZeros = false,
}: SalePriceProps) => {
  const { formatPrice } = useFormatPrice()

  if (!price) {
    return null
  }

  const { regular, final, save } = price
  const saveUpToButtonInlineStyle = {
    backgroundColor: saveUpToButtonStyle?.backgroundColor || '#892B7D',
    color: saveUpToButtonStyle?.textColor || '#ffffff',
  }

  const defaultPrice = regular?.centAmount ? regular : final
  const fomattedPrice = getFormattedPrice(
    formatPrice(defaultPrice),
    truncateDecimalZeros
  )

  const formattedSavePrice =
    save?.type === 'percent'
      ? getFormattedPercent(save as SalePriceSavePercent, truncateDecimalZeros)
      : getFormattedPrice(formatPrice(save as BaseMoney), truncateDecimalZeros)

  return (
    <div
      className={
        wrapperClassName || 'flex flex-col gap-4 lg:gap-6 mt-2 lg:mt-8'
      }>
      {!!fomattedPrice && (
        <Typography
          tag={TypographyTag.p}
          variant={
            regularPriceTypographyVariant ||
            TypographyVariant.BodyRegularExtraLineHeight
          }
          className={classNames(
            styles.regularPriceLabel,
            regularPriceClassName
          )}>
          {wasText && <span>{wasText} </span>}
          <span>{fomattedPrice}</span>
        </Typography>
      )}
      {!!formattedSavePrice && (
        <Typography
          tag={TypographyTag.p}
          variant={
            savePriceTypographyVariant ||
            TypographyVariant.BodyRegularExtraLineHeight
          }
          style={saveUpToButtonInlineStyle}
          className={classNames(styles.savePrice, savePriceClassName)}>
          <span>{saveText} </span>
          <span>{formattedSavePrice}</span>
        </Typography>
      )}
    </div>
  )
}

const SalePriceWithoutSaveUpTo = ({
  price,
  regularPriceClassName,
  savePriceClassName,
  finalPriceClassName,
  finalPriceTypography,
  wasText = 'Was',
  saveText = 'Save',
  textColor,
}: SalePriceProps) => {
  const { formatPrice } = useFormatPrice()
  const savePriceValue = useMemo(() => {
    return getFormattedSavePrice(price, formatPrice)
  }, [price, formatPrice])

  if (!price) {
    return null
  }
  const { regular, final } = price

  return (
    <div className='flex flex-col gap-2'>
      <div className={styles.priceWrapper}>
        {(!!regular?.centAmount || !!savePriceValue.value) && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyLarge}
            className={regularPriceClassName}
            style={{ color: textColor }}>
            {wasText} {formatPrice(regular)}
          </Typography>
        )}
        {savePriceValue.type && !!savePriceValue.value && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyLargeBold}
            className={savePriceClassName}
            style={{ color: textColor }}>
            {saveText} {savePriceValue?.value}
          </Typography>
        )}
      </div>
      {!!final?.centAmount && (
        <Typography
          tag={TypographyTag.p}
          variant={finalPriceTypography?.variant || TypographyVariant.Heading3}
          className={finalPriceClassName ?? '!text-primary-heading'}
          style={{ color: textColor }}>
          {formatPrice(final)}
        </Typography>
      )}
    </div>
  )
}

const SalePrice = (props: SalePriceProps) => {
  const { displayAsButton = true, ...rest } = props

  return (
    <div>
      {displayAsButton ? (
        <SalePriceWithSaveUpto {...rest} />
      ) : (
        <SalePriceWithoutSaveUpTo {...rest} />
      )}
    </div>
  )
}

export { SalePrice }
