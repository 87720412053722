import React, { FC, useCallback, useMemo, useState } from 'react'
import { Image as ImageType } from 'shared-types'
import { JAYCAR_CATEGORY_NAMES } from './categoryMediator'
import { configureOptions, dataURItoBuffer, fetchWithRetry } from './util'
import {
  VisionSearchContext,
  defaultVisionSearchContextValue,
} from './VisionSearchContext'
import {
  ErrorType,
  VISION_STATE,
  VisionSearchErrorState,
  VisionSearchImage,
  VisionSearchRecognitionResult,
} from './visionSearchStore.types'

const END_POINT = process.env.NEXT_PUBLIC_VISION_SEARCH_ENDPOINT
const PREDICT_MAX_DEPTH = 2
const SHOWN_RECOGNIZED_CNT = 6
const CONFIDENCE_THRESHHOLD = 0.4
const UPLOAD_STATES = {
  INIT: 0.1,
  PRESIGNED: 0.15,
  UPLOADING: 0.25,
  UPLOADED: 0.4,
  FILE_READ: 0.52,
  PROCESSING: 0.64,
  PROCESSING_LEVEL_0: 0.95,
  PROCESSING_LEVEL_1: 0.99,
  PROCESSING_LEVEL_2: 1,
  PREDICTED: 1,
}

type VisionSearchProviderType = {
  children: React.JSX.Element
}

export const VisionSearchProvider: FC<VisionSearchProviderType> = ({
  children,
}) => {
  const [visionSearchOpen, setVisionSearchOpen] = useState<boolean>(
    defaultVisionSearchContextValue.visionSearchOpen
  )
  const [helpModalActive, setHelpModalActive] = useState<boolean>(
    defaultVisionSearchContextValue.helpModalActive
  )
  const [visionState, setVisionState] = useState<VISION_STATE>(
    defaultVisionSearchContextValue.visionState as VISION_STATE
  )
  const [uploadedImage, setUploadedImage] = useState<ImageType>(
    defaultVisionSearchContextValue.uploadedImage
  )
  const [capturedImageData, setCapturedImageData] = useState<string>(
    defaultVisionSearchContextValue.capturedImageData
  )
  const [imageUploadProgress, setImageUploadProgress] = useState<number>(
    defaultVisionSearchContextValue.imageUploadProgress
  )
  const [cameraActive, setCameraActive] = useState<boolean>(
    defaultVisionSearchContextValue.cameraActive
  )
  const [error, setError] = useState<ErrorType>(
    defaultVisionSearchContextValue.error
  )
  const [recognitionResults, setRecognitionResults] = useState<
    Array<VisionSearchRecognitionResult>
  >(defaultVisionSearchContextValue.recognitionResults)

  const isUploading = useMemo(() => {
    return visionState === 'processing'
  }, [visionState])

  const resetVisionSearch = useCallback(() => {
    setVisionState(defaultVisionSearchContextValue.visionState as VISION_STATE)
    setUploadedImage(defaultVisionSearchContextValue.uploadedImage)
    setImageUploadProgress(defaultVisionSearchContextValue.imageUploadProgress)
    setRecognitionResults(defaultVisionSearchContextValue.recognitionResults)
    setCapturedImageData(defaultVisionSearchContextValue.capturedImageData)
    setError(defaultVisionSearchContextValue.error)
  }, [])

  const setErrorState = useCallback((errorState: VisionSearchErrorState) => {
    setCameraActive(defaultVisionSearchContextValue.cameraActive)
    setVisionState(defaultVisionSearchContextValue.visionState as VISION_STATE)
    setError({
      active: true,
      notification:
        'There was an error and your image could not be uploaded. Please try again.',
      ...errorState,
    })
  }, [])

  const clearCapturedImage = useCallback(() => {
    setCapturedImageData(defaultVisionSearchContextValue.capturedImageData)
  }, [])

  const loadUploadedImage = useCallback(
    (imageUrl: string) => {
      setVisionState('processing')
      const img = new Image()
      img.src = imageUrl

      img.onload = () => {
        const image: ImageType = {
          src: img.src,
          height: img.height,
          width: img.width,
          altText: 'Vision Search Image',
        }

        setUploadedImage(image)
        setVisionState('processing')
      }

      img.onerror = () => {
        setErrorState({
          statusCode: -3,
          error: 'processing',
        })
      }
    },
    [setErrorState]
  )

  const predict = useCallback(
    async (imageID: string, categoryID: string, depth: number) => {
      setVisionState('processing')

      const options = configureOptions('POST')
      options.body = JSON.stringify({
        imageID,
        categoryID,
      })

      try {
        setImageUploadProgress(UPLOAD_STATES[`PROCESSING_LEVEL_${depth}`])

        const data = await fetchWithRetry(`${END_POINT}predict`, options)

        if (data && data?.recognised) {
          const validRecognised: Array<VisionSearchRecognitionResult> =
            Object.entries(data?.recognised)
              .map(([key, value]) => {
                return {
                  id: key,
                  confidence: value as number,
                  category: JAYCAR_CATEGORY_NAMES[key],
                  model: categoryID,
                  name: key,
                  depth,
                  active: true,
                }
              })
              .filter((value) => {
                return value.confidence >= CONFIDENCE_THRESHHOLD
              })
              .sort((a, b) => {
                return b.confidence - a.confidence
              })

          // Sort the array by confidence in descending order
          setRecognitionResults((result) => {
            return [...validRecognised, ...result].slice(
              0,
              SHOWN_RECOGNIZED_CNT
            )
          })

          if (depth < PREDICT_MAX_DEPTH) {
            depth++
            predict(imageID, validRecognised[0].id, depth)
          } else {
            setVisionState('predicted')
          }
        }
      } catch (error) {
        setErrorState({
          statusCode: error.status,
          error: 'predict-catch',
        })
      }
    },
    [setErrorState]
  )

  const uploadImageBinary = useCallback(
    async (
      binaryData: string | ArrayBuffer,
      presignedImage: VisionSearchImage
    ) => {
      setImageUploadProgress(UPLOAD_STATES.FILE_READ)
      const options = configureOptions('PUT')
      options.body = binaryData
      await fetch(presignedImage.presignedUrl, options)
        .then((response) => {
          if (!response.ok) {
            setErrorState({
              statusCode: response.status,
              error: 'upload-image-response',
            })
          } else {
            setImageUploadProgress(UPLOAD_STATES.UPLOADED)
            setVisionState('processing')
            loadUploadedImage(presignedImage.url)

            predict(presignedImage.imageId, 'main_category_model', 0)
            setImageUploadProgress(UPLOAD_STATES.PROCESSING)
          }
        })
        .catch((error) => {
          setErrorState({
            statusCode: error.status,
            error: 'upload-image-catch',
          })
          console.error('upload-image-catch', error)
        })
    },
    [loadUploadedImage, predict, setErrorState]
  )

  const uploadFile = useCallback(
    (imageFile: File, presignedImage: VisionSearchImage) => {
      if (!imageFile) {
        setErrorState({
          statusCode: -1,
          error: 'no-image-to-upload',
        })
        return
      }

      setVisionState('processing')
      setImageUploadProgress(UPLOAD_STATES.UPLOADING)

      const reader = new FileReader()
      reader.onload = () => {
        uploadImageBinary(reader.result, presignedImage)
      }

      reader.readAsArrayBuffer(imageFile)
    },
    [setErrorState, uploadImageBinary]
  )

  const uploadCapture = useCallback(
    async (imageData: string, presignedImage: VisionSearchImage) => {
      try {
        const binaryData = dataURItoBuffer(imageData)
        await uploadImageBinary(binaryData, presignedImage)
      } catch (error) {
        setErrorState({
          statusCode: -4,
          error: 'convert-capture-upload',
        })
        console.error('convert-capture-upload', error)
      }
    },
    [setErrorState, uploadImageBinary]
  )

  const presignUpload = useCallback(
    async (imageInfo: File | string) => {
      setImageUploadProgress(UPLOAD_STATES.INIT)

      setVisionState('processing')

      await fetch(`${END_POINT}upload-presigned`, configureOptions('POST'))
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          try {
            if (data.presigned_url) {
              const url = data?.presigned_url.split('?')
              const imageId = data.fileID.includes('.')
                ? data?.fileID.split('.')[0]
                : data.fileID

              const presignedImage = {
                imageId,
                url: url[0],
                presignedUrl: data.presigned_url,
              }

              setImageUploadProgress(UPLOAD_STATES.PRESIGNED)
              setVisionState('processing')

              if (typeof imageInfo === 'string') {
                uploadCapture(imageInfo, presignedImage)
              } else {
                uploadFile(imageInfo, presignedImage)
              }
            }
          } catch (error) {
            setErrorState({
              statusCode: -6,
              error: 'upload-presigned-url-error',
            })
          }
        })
        .catch((error) => {
          setErrorState({
            statusCode: error.status,
            error: 'upload-presigned-catch',
          })
        })
    },
    [setErrorState, uploadCapture, uploadFile]
  )

  const setUploadImageFile = useCallback(
    (imageFile: File) => {
      presignUpload(imageFile)
    },
    [presignUpload]
  )

  const setCapturedImage = useCallback(
    (imageData: string) => {
      setCapturedImageData(imageData)
      presignUpload(imageData)
    },
    [presignUpload]
  )

  const conetextValue = useMemo(() => {
    return {
      visionSearchOpen,
      setVisionSearchOpen,
      helpModalActive,
      setHelpModalActive,
      visionState,
      setVisionState,
      isUploading,
      uploadedImage,
      recognitionResults,
      setRecognitionResults,
      capturedImageData,
      setCapturedImage,
      imageUploadProgress,
      resetVisionSearch,
      clearCapturedImage,
      setUploadImageFile,
      cameraActive,
      setCameraActive,
      error,
      setError,
    }
  }, [
    visionSearchOpen,
    setVisionSearchOpen,
    helpModalActive,
    setHelpModalActive,
    visionState,
    setVisionState,
    isUploading,
    uploadedImage,
    recognitionResults,
    capturedImageData,
    setCapturedImage,
    imageUploadProgress,
    resetVisionSearch,
    clearCapturedImage,
    setUploadImageFile,
    cameraActive,
    setCameraActive,
    error,
    setError,
  ])

  return (
    <VisionSearchContext.Provider value={conetextValue}>
      {children}
    </VisionSearchContext.Provider>
  )
}
