import { useCallback, useState } from 'react'
import { Star } from 'react-feather'
import { Compose, composeThemeFromProps } from '@css-modules-theme/react'
import classNames from 'classnames'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'

import defaultStyles from './ReviewStars.module.css'
import {
  RatingDirection,
  RatingRange,
  RatingSize,
  ReviewStarsProps,
} from './ReviewStars.types'

const halfStarSizes = {
  bg: {
    width: 30,
    height: 30,
  },
  sm: {
    width: 16,
    height: 16,
  },
  xs: {
    width: 14,
    height: 14,
    top: 1,
  },
}

export const ReviewStars = ({
  className,
  theme,
  rating: defaultRating,
  size = 'bg',
  reviewCount = 0,
  editable,
  displayReviewCount,
  dataTestId,
  name,
  ...restProps
}: ReviewStarsProps) => {
  const styles = composeThemeFromProps(
    defaultStyles,
    { theme },
    { compose: Compose.Merge }
  )

  const [rating, setRating] = useState(defaultRating || null)
  const [hover, setHover] = useState(null)

  const renderHalfStar = useCallback(
    (value: RatingRange, side: RatingDirection, size: RatingSize) => {
      return (
        <Star
          className={classNames(styles.star, {
            [styles.size_xs]: size === 'xs',
            [styles.size_bg]: size === 'bg',
            [styles.size_sm]: size === 'sm',
            [styles.checkedStar]: value <= (hover ?? rating),
            [styles.rightPartStar]: side === 'right',
            [styles.leftPartStar]: side === 'left',
          })}
          {...halfStarSizes[size]}
          onClick={() => {
            return editable && setRating(value)
          }}
          onMouseEnter={() => {
            return editable && setHover(value)
          }}
          onMouseLeave={() => {
            return editable && setHover(null)
          }}
        />
      )
    },
    [editable, hover, rating, styles, setRating, setHover]
  )

  const five = 5
  const half = 0.5

  return (
    <div
      className={classNames(styles.reviewWrapper, className)}
      data-test-id={dataTestId}>
      <input
        type='radio'
        name={name || 'rating'}
        className={styles.radio}
        value={rating}
        {...restProps}
      />
      {[...Array(five)].map((_star, i) => {
        return (
          <div
            key={i}
            className={classNames(styles.label, {
              [styles.star_sm]: size === 'sm',
              [styles.star_xs]: size === 'xs',
              [styles.star_bg]: size === 'bg',
            })}>
            {renderHalfStar(i + half, 'left', size)}
            {renderHalfStar(i + 1, 'right', size)}
          </div>
        )
      })}
      {displayReviewCount && (
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodySmall}
          className='!pb-0'>
          <span
            className={classNames('block', {
              [styles.reviewCount_xs]: size === 'xs',
              [styles.reviewCount_sm]: size === 'sm',
              [styles.reviewCount_bg]: size === 'bg',
            })}>
            {rating?.toFixed(1) || '0.0'} ({reviewCount || 0})
          </span>
        </Typography>
      )}
    </div>
  )
}
