import { useCallback, useMemo, useState } from 'react'
import { IconInfoCircle, IconTruck } from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import Card from '~/components/Card/Card'
import { ProductDeliverToPostcodeStatusCardProps } from '~/components/ProductDeliverToPostcodeStatusCard/ProductDeliverToPostcodeStatusCard.types'
import Status from '~/components/Status/Status'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useSession, useToast, useUserDeliveryPostcode } from '~/hooks'
import { Client } from '~/customClients/client'

const ProductDeliverToPostcodeStatusTooptip = dynamic(
  async () => {
    const mod = await import('./ProductDeliverToPostcodeStatusTooptip')
    return mod.ProductDeliverToPostcodeStatusTooptip
  },
  {
    loading: () => {
      return (
        <span className='z-[11] relative inline-block cursor-pointer'>
          <IconInfoCircle
            size={20}
            color='var(--product-status-tooltip-color)'
            className='inline'
          />
        </span>
      )
    },
    ssr: false,
  }
)

export const ProductDeliverToPostcodeStatusCard = ({
  postcode,
  city,
  status,
  deliveryMethods,
  sku,
}: ProductDeliverToPostcodeStatusCardProps) => {
  const {
    promptUserPostcodeDrawer,
    deliveryMethods: providerDeliveryMethod,
    postcode: providerPostcode,
    city: providerCity,
  } = useUserDeliveryPostcode()
  const { session } = useSession()
  const { showNotification, closeNotification } = useToast()
  const [loading, setLoading] = useState(false)

  const deliveryPostCode = useMemo(() => {
    return postcode || providerPostcode
  }, [postcode, providerPostcode])

  const deliveryCity = useMemo(() => {
    return city || providerCity
  }, [city, providerCity])

  const getDeliveryMethods = useCallback(async () => {
    if (!postcode) {
      return Promise.resolve(deliveryMethods || providerDeliveryMethod)
    }

    setLoading(true)
    const response = await Client.store.getDeliverToPostcodeAvailabilityStatus(
      session,
      postcode,
      sku
    )
    const deliveryMethodsError = response.getErrorSafe()
    if (deliveryMethodsError) {
      showNotification({
        children: deliveryMethodsError.message,
        autoClose: true,
        id: 'deliveryMethods-error',
        type: 'error',
        onClose: closeNotification,
      })
    }

    setLoading(false)
    return response.getValueSafe()?.deliveryMethods
  }, [
    closeNotification,
    deliveryMethods,
    postcode,
    providerDeliveryMethod,
    session,
    showNotification,
    sku,
  ])

  return (
    <Card
      title={
        <>
          <Typography
            tag={TypographyTag.span}
            variant={TypographyVariant.BodyRegularBold}
            className='mr-2'>
            Deliver to{' '}
            {deliveryPostCode && (
              <span className='underline'>
                {deliveryCity}, {deliveryPostCode}
              </span>
            )}
          </Typography>
          {deliveryMethods?.length > 0 && (
            <ProductDeliverToPostcodeStatusTooptip
              loading={loading}
              getDeliveryMethods={getDeliveryMethods}
            />
          )}
        </>
      }
      variant='default'
      icon={<IconTruck size={24} />}>
      <div className='pl-8'>
        {deliveryPostCode ? (
          <Status
            label={status === 'available' ? 'Available' : 'Not Available'}
            status={status === 'available' ? 'success' : 'error'}
          />
        ) : (
          <Typography
            tag={TypographyTag.div}
            variant={TypographyVariant.BodyRegular}>
            Add postcode for delivery availability
          </Typography>
        )}
      </div>
      <button
        aria-label='Add postcode for delivery availability'
        type='button'
        className='absolute inset-0 bg-transparent border-0 outline-0 z-10 w-full'
        onClick={() => {
          return promptUserPostcodeDrawer()
        }}>
        {' '}
      </button>
    </Card>
  )
}
