import { ProductShippingCardStackProps } from '~/components/ProductShippingCardStack/ProductShippingCardStack.types'
import CardStack from '../CardStack/CardStack'
import { ProductClickAndCollectStatusCard } from '../ProductClickAndCollectStatusCard'
import { ProductDeliverToPostcodeStatusCard } from '../ProductDeliverToPostcodeStatusCard'

export const ProductShippingCardStack = ({
  deliverToPostcodeAvailabilityStatus,
  storeAvailabilityStatus,
  sku,
}: ProductShippingCardStackProps) => {
  return (
    <CardStack>
      {deliverToPostcodeAvailabilityStatus && (
        <ProductDeliverToPostcodeStatusCard
          {...deliverToPostcodeAvailabilityStatus}
          sku={sku}
        />
      )}
      {storeAvailabilityStatus && (
        <ProductClickAndCollectStatusCard
          {...storeAvailabilityStatus}
          sku={sku}
        />
      )}
    </CardStack>
  )
}
