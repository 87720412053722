import React from 'react'
import { RatingsProps } from './Ratings.types'
import { ReviewStars } from './ReviewStars'

export const Ratings = ({
  name,
  rating,
  reviewCount,
  iconSize = 'sm',
}: RatingsProps) => {
  return (
    <div>
      <ReviewStars
        name={name}
        rating={rating}
        reviewCount={reviewCount}
        size={iconSize}
        editable={false}
        displayReviewCount
      />
    </div>
  )
}
