export const JAYCAR_CATEGORY_NAMES = {
  SoundVideo: 'Sound & Video',
  PowerBatteries: 'Power & Batteries',
  HobbiesGadgets: 'Hobbies & Gadgets',
  SecuritySurveillance: 'Security & Surveillance',
  ToolsTestEquipment: 'Tools & Test Equipment',
  ComputingCommunication: 'Computing & Communication',
  CablesConnectors: 'Cables & Connectors',
  OutdoorsAutomotive: 'Outdoors & Automotive',
  ComponentsElectromechanical: 'Components & Electromechanical',
  SpeakersAccessories: 'Speakers & Accessories',
  BatteryChargers: 'Battery Chargers',
  RemoteControl: 'Remote Control',
  PropertyProtection: 'Property Protection',
  WorkbenchGear: 'Workbench Gear',
  MemoryandMedia: 'Memory and Media',
  CableManagementandTools: 'Cable Management and Tools',
  Camping: 'Camping',
  HeatsinksFans: 'Heatsinks & Fans',
  PowerConversionGeneration: 'Power Conversion & Generation',
  SolarPower: 'Solar Power',
  MainsAccessories: 'Mains Accessories',
  Headphones: 'Headphones',
  PartyEquipment: 'Party Equipment',
  Microphones: 'Microphones',
  SwitchersandConverters: 'Switchers and Converters',
  CablesandAdaptors: 'Cables and Adaptors',
  TVHardware: 'TV Hardware',
  RadiosMusicPlayers: 'Radios & Music Players',
  Networking: 'Networking',
  Scienceandlearning: 'Science and learning',
  Communication: 'Communication',
  SolderingIrons: 'Soldering Irons',
  CablesAdaptors: 'Cables & Adaptors',
  Gadgets: 'Gadgets',
  Tools: 'Tools',
  CarPowerAccessories: 'Car Power Accessories',
  ElectricalTesters: 'Electrical Testers',
  BatteryAccessories: 'Battery Accessories',
  PowerConnectors: 'Power Connectors',
  EnclosuresPanelHardware: 'Enclosures & Panel Hardware',
  CarSecurityEntertainment: 'Car Security & Entertainment',
  Electronicskits: 'Electronics kits',
  Semiconductors: 'Semiconductors',
  Multimeters: 'Multimeters',
  RechargeableBatteries: 'Rechargeable Batteries',
  TerminalBlocksHeaders: 'Terminal Blocks & Headers',
  OptoelectronicsLEDsandLighting: 'Optoelectronics (LEDs and Lighting)',
  CommunicationNetworkConnectors: 'Communication & Network Connectors',
  Switches: 'Switches',
  SecurityCameras: 'Security Cameras',
  PowerSupplies: 'Power Supplies',
  AudioVideoConnectors: 'Audio Video Connectors',
  AudioVideoCables: 'Audio Video Cables',
  Power: 'Power',
  ComputerConnectors: 'Computer Connectors',
  Resistors: 'Resistors',
  WallplatesandKeystone: 'Wallplates and Keystone',
  FusesCircuitProtection: 'Fuses & Circuit Protection',
  EnvironmentMeters: 'Environment Meters',
  Capacitors: 'Capacitors',
  Nonrechargeablebatteries: 'Non rechargeable batteries',
  WireCableRolls: 'Wire & Cable Rolls',
  Lighting: 'Lighting',
  Relays: 'Relays',
  CCTV: 'CCTV',
  Peripherals: 'Peripherals',
  OtherComponents: 'Other Components',
  Arduino: 'Arduino',
  WeatherStations: 'Weather Stations',
  MiniComputerBoards: 'Mini Computer Boards',
  HealthandSafety: 'Health and Safety',
  Amplifiers: 'Amplifiers',
  ChargerAccessories: 'Charger Accessories',
  MotorsServosSolenoids: 'Motors,Servos & Solenoids',
  IntercomsDoorbells: 'Intercoms & Doorbells',
  Chemicalscleanerslubricants: 'Chemicals cleaners, lubricants',
  OpticalMedia: 'Optical Media',
  Cabletieswrapsgrommets: 'Cable ties, wraps, grommets',
  FansPersonalCooling: 'Fans & Personal Cooling',
  StructuralHeatsinks: 'Structural Heatsinks',
  DCACInverters: 'DC-AC Inverters',
  SolarChargeControllers: 'Solar Charge Controllers',
  MainsControlProtection: 'Mains Control & Protection',
  Wirelessheadphones: 'Wireless headphones',
  DJEquipment: 'DJ Equipment',
  Wiredmicrophones: 'Wired microphones',
  Switchers: 'Switchers',
  '253565mmCables': '2.5/3.5/6.5mm Cables',
  Remotecontrols: 'Remote controls',
  Worldbandotherradios: 'World band & other radios',
  NetworkingAntennas: 'Networking Antennas',
  Books: 'Books',
  Antennas: 'Antennas',
  SolderingStations: 'Soldering Stations',
  OtherComputerAdaptors: 'Other Computer Adaptors',
  LoungeroomBedroom: 'Loungeroom & Bedroom',
  ScrewsFasteners: 'Screws & Fasteners',
  JumpStartersBatteryCare: 'Jump Starters & Battery Care',
  TestJumpersLeads: 'Test & Jumpers Leads',
  BatteryTerminals: 'Battery Terminals',
  Quickconnect: 'Quick connect',
  RackMount: 'Rack Mount',
  CarAudioEntertainment: 'Car Audio & Entertainment',
  PCBsBreadboards: 'PCBs, Breadboards',
  Transistors: 'Transistors',
  ProbesAccessories: 'Probes & Accessories',
  SLAGel: 'SLA & Gel',
  TerminalBlocks: 'Terminal Blocks',
  LEDs: 'LEDs',
  HeadersIDC: 'Headers & IDC',
  PushbuttonSwitches: 'Pushbutton Switches',
  DummyCameras: 'Dummy Cameras',
  DCOutput: 'DC Output',
  '253565mm': '2.5/3.5/6.5mm',
  VGA: 'VGA',
  UPS: 'UPS',
  DiskDrivesSATAMolex: 'Disk Drives & SATA/Molex',
  Thermistors: 'Thermistors',
  AudiovideoWallplatesInserts: 'Audio/video Wallplates & Inserts',
  '3ag5agFuses': '3ag/5ag Fuses',
  LightMeters: 'Light Meters',
  Ceramic: 'Ceramic',
  ButtonCell: 'Button Cell',
  PowerandHookupCable: 'Power and Hookup Cable',
  HouseSpecialityLighting: 'House & Speciality Lighting',
  DILRelays: 'DIL Relays',
  CCTVcablesandaccessories: 'CCTV cables and accessories',
  KeyboardsMice: 'Keyboards & Mice',
  ModulesSubAssemblies: 'Modules & Sub Assemblies',
  ArduinoBooks: 'Arduino Books',
  Indoor: 'Indoor',
  RaspberryPi: 'Raspberry Pi',
  Healthmonitoring: 'Health monitoring',
  Wiredheadphones: 'Wired headphones',
  Buzzers: 'Buzzers',
  MicrophoneAccessories: 'Microphone Accessories',
  Extenders: 'Extenders',
  Speakerbracketshardware: 'Speaker brackets & hardware',
  ComponentSpeakers: 'Component Speakers',
  GeneralSpeakers: 'General Speakers',
  SpeakerStands: 'Speaker Stands',
  TVMountingBrackets: 'TV Mounting Brackets',
  Musicplayers: 'Music players',
  MainsHardware: 'Mains Hardware',
  Funandgames: 'Fun and games',
  ShortCircuitsProjects: 'Short Circuits Projects',
  SpacersStandoffs: 'Spacers & Standoffs',
  ScienceProjects: 'Science Projects',
  KitchenBathroom: 'Kitchen & Bathroom',
  Health: 'Health',
  PanelHardware: 'Panel Hardware',
  StorageCases: 'Storage & Cases',
  PliersCutters: 'Pliers & Cutters',
  NonStructuralHeatsinks: 'Non-Structural Heatsinks',
  BatteryHoldersSnaps: 'Battery Holders & Snaps',
  Cablemanagement: 'Cable management',
  Specialisedtools: 'Specialised tools',
  HeatsinkCompoundsAccessories: 'Heatsink Compounds & Accessories',
  AntistatictoolsWorkMats: 'Anti-static tools & Work Mats',
  TerminalBarriersStrips: 'Terminal Barriers & Strips',
  AutomotiveKits: 'Automotive Kits',
  SolderAccessories: 'Solder & Accessories',
  PowerKits: 'Power Kits',
  Conduittubes: 'Conduit tubes',
  SolarMountingHardware: 'Solar Mounting Hardware',
  AudioVideoKits: 'Audio/Video Kits',
  HouseholdKits: 'Household Kits',
  ControlAutomationKits: 'Control & Automation Kits',
  ComputingProgrammingKits: 'Computing & Programming Kits',
  AlarmsSirens: 'Alarms & Sirens',
  Robotics: 'Robotics',
  ReedSwitches: 'Reed Switches',
  SecuritySigns: 'Security Signs',
  DoorSecurity: 'Door Security',
  TestMeasurementKits: 'Test & Measurement Kits',
  VehicleTrackingandSecurity: 'Vehicle Tracking and Security',
  Magnets: 'Magnets',
  RFIDandAccessControl: 'RFID and Access Control',
  HomeAutomation: 'Home Automation',
  Sensors: 'Sensors',
  Wallplates: 'Wallplates',
  SLAGELChargers: 'SLA/GEL Chargers',
  PowerGenerators: 'Power Generators',
  LiionLipoChargers: 'Li-ion/Li-po Chargers',
  Transformers: 'Transformers',
  USBPowerCharging: 'USB Power & Charging',
  LaptopPowerSupplies: 'Laptop Power Supplies',
  DCDCConverters: 'DC-DC Converters',
  Laboratory: 'Laboratory',
  Switchmode: 'Switchmode',
  PowerBanksPhoneChargers: 'Power Banks & Phone Chargers',
  ACOutput: 'AC Output',
  Crimplugsandterminals: 'Crimp lugs and terminals',
  OpenFrameDINRailType: 'Open Frame DIN Rail Type',
  PowerboardsAdaptors: 'Powerboards & Adaptors',
  RCA: 'RCA',
  TapeandAdhesives: 'Tape and Adhesives',
  FMEFTypeNType: 'FME/F-Type/N-Type',
  SMA: 'SMA',
  DSubSerial: 'D-Sub/Serial',
  BananaBindingPosts: 'Banana/Binding Posts',
  CircularDIN: 'Circular/DIN',
  PALandCoaxial: 'PAL and Coaxial',
  Toslink: 'Toslink',
  ICHardware: 'IC Hardware',
  Cat5Cat6NetworkCables: 'Cat5/Cat6 Network Cables',
  RJ45RJ11RJ12Connectors: 'RJ-45/RJ-11/RJ-12 Connectors',
  IEC: 'IEC',
  HighCurrentAnderson: 'High Current & Anderson',
  DCpower: 'DC power',
  BNC: 'BNC',
  Multipin: 'Multi pin',
  UHF: 'UHF',
  AutomotiveConnectors: 'Automotive Connectors',
  '12VDCCigaretteSocketGear': '12VDC Cigarette Socket Gear',
  HDMI: 'HDMI',
  Traveladaptors: 'Travel adaptors',
  Potentiometer: 'Potentiometer',
  Blankwallplatesinserts: 'Blank wallplates & inserts',
  SolarCablesConnectors: 'Solar Cables & Connectors',
  PowerWallplatesInserts: 'Power Wallplates & Inserts',
  ComputerNetworking: 'Computer & Networking',
  RCSpareParts: 'RC Spare Parts',
  Extensionleads: 'Extension leads',
  TelephoneConnectors: 'Telephone Connectors',
  Fans: 'Fans',
  CameraAccessories: 'Camera Accessories',
  Covert: 'Covert',
  Oscilloscopes: 'Oscilloscopes',
  M205Fuses: 'M205 Fuses',
  Magnifiers: 'Magnifiers',
  VoltageDetectors: 'Voltage Detectors',
  DigitalMultimeters: 'Digital Multimeters',
  SoundMeters: 'Sound Meters',
  Thermometers: 'Thermometers',
  InspectionCameras: 'Inspection Cameras',
  WaterMoisturepHMeters: 'Water, Moisture & pH Meters',
  AnalogueMultimeters: 'Analogue Multimeters',
  GeneralTesters: 'General Testers',
  Monolithic: 'Monolithic',
  OtherResistors: 'Other Resistors',
  Scales: 'Scales',
  BatteryTesters: 'Battery Testers',
  Electrolytic: 'Electrolytic',
  MKT: 'MKT',
  Greencaps: 'Greencaps',
  CrystalsSCRSTriacsDiacs: 'Crystals, SCRS, Triacs & Diacs',
  Othercapacitors: 'Other capacitors',
  Varistors: 'Varistors',
  MainsX2Class: 'Mains X2 Class',
  OtherFusesHolders: 'Other Fuses & Holders',
  Supercaps: 'Super caps',
  MetalFilm: 'Metal Film',
  CarbonFilm: 'Carbon Film',
  Wirewound: 'Wirewound',
  SurgeProtection: 'Surge Protection',
  NiMHNiCd: 'Ni-MH/Ni-Cd',
  Trimpots: 'Trimpots',
  Tantalum: 'Tantalum',
  MetalisedPolypropylene: 'Metalised Polypropylene',
  Lithium: 'Lithium',
  MotorStartCapacitor: 'Motor Start Capacitor',
  Trimmer: 'Trimmer',
  CircuitBreakers: 'Circuit Breakers',
  FusesRelays: 'Fuses & Relays',
  Bladefuses: 'Blade fuses',
  Alkaline: 'Alkaline',
  OtherSwitches: 'Other Switches',
  OtherBatteries: 'Other Batteries',
  RockerSwitches: 'Rocker Switches',
  IncandescentGlobesAccessories: 'Incandescent Globes & Accessories',
  Laserandpartylighting: 'Laser and party lighting',
  ToggleSwitches: 'Toggle Switches',
  Diodes: 'Diodes',
  KeySwitches: 'Key Switches',
  MicroSwitches: 'Micro Switches',
  TactileSwitches: 'Tactile Switches',
  RotarySwitches: 'Rotary Switches',
  SlideSwitches: 'Slide Switches',
  DILSwitches: 'DIL Switches',
  PCBmount: 'PCB mount',
  Cradlemount: 'Cradle mount',
  Automotiverelays: 'Automotive relays',
  ThermalSwitchesFuses: 'Thermal Switches/Fuses',
  Solidstate: 'Solid state',
  TrailerLightingCarWiring: 'Trailer Lighting & Car Wiring',
  Panelmount: 'Panel mount',
  CrimpersWireStrippers: 'Crimpers & Wire Strippers',
  Screwdrivers: 'Screwdrivers',
  GasSolderingIrons: 'Gas Soldering Irons',
  Tweezers: 'Tweezers',
  DrillsElectricTools: 'Drills & Electric Tools',
  StandsSafety: 'Stands & Safety',
  AVCable: 'AV Cable',
  ElectricSolderingIrons: 'Electric Soldering Irons',
  SpeakerMicrophoneCable: 'Speaker & Microphone Cable',
  ComputerDataSensorCable: 'Computer Data & Sensor Cable',
  CircularDINSVideo: 'Circular/DIN/S-Video',
  IntercomAlarmCCTVCable: 'Intercom/Alarm/CCTV Cable',
  XLRSpeakonCables: 'XLR/Speakon Cables',
  RCAAVCables: 'RCA/AV Cables',
  CommunicationCable: 'Communication Cable',
  Heatshrink: 'Heatshrink',
  ToslinkSMACables: 'Toslink & SMA Cables',
  DVI: 'DVI',
  Smartphoneaccessories: 'Smartphone accessories',
  RFAntennaCable: 'RF/Antenna Cable',
  GeneralPurposeCable: 'General Purpose Cable',
  CoaxialTVCables: 'Coaxial/TV Cables',
  Ftype: 'F-type',
  DisplayPort: 'DisplayPort',
  Clocks: 'Clocks',
  Converters: 'Converters',
  GearsTransmission: 'Gears & Transmission',
  Speakersmicrophones: 'Speakers & microphones',
  Hubs: 'Hubs',
  PersonalSecurity: 'Personal Security',
  TelephoneWallplatesInserts: 'Telephone Wallplates & Inserts',
  NetworkAdaptors: 'Network Adaptors',
  LogicICs: 'Logic ICs',
  '12VDCCampingAccessories': '12VDC Camping Accessories',
  TelephonesAccessories: 'Telephones & Accessories',
  CarLights: 'Car Lights',
  LEDStripLighting: 'LED Strip Lighting',
  LinearICs: 'Linear ICs',
  OtherICs: 'Other ICs',
  FerritesInductorsSuppression: 'Ferrites, Inductors, Suppression',
  SolarPanels: 'Solar Panels',
  RectifiersVoltageRegulators: 'Rectifiers & Voltage Regulators',
  FETs: 'FETs',
  Microcontrollers: 'Microcontrollers',
  LowPowerSchottky: 'Low Power Schottky',
  GasDetectors: 'Gas Detectors',
  LiionLiPo: 'Li-ion/Li-Po',
  CardReaders: 'Card Readers',
  NiMHNiCdChargers: 'Ni-MH/Ni-Cd Chargers',
  LaptopAccessories: 'Laptop Accessories',
  InCarChargers: 'In Car Chargers',
  CabinCaravanlights: 'Cabin & Caravan lights',
  PhoneGPSTabletHolders: 'Phone/GPS/Tablet Holders',
  USB: 'USB',
  AutomotiveTestEquipment: 'Automotive Test Equipment',
  Microscopes: 'Microscopes',
  Anemometers: 'Anemometers',
  Survivalgear: 'Survival gear',
  SDCards: 'SD Cards',
  BikeLights: 'Bike Lights',
  Globes: 'Globes',
  Otherrelays: 'Other relays',
  Headtorches: 'Head torches',
  PanelMeters: 'Panel Meters',
  Outdoor: 'Outdoor',
  Torches: 'Torches',
  Wirelessmicrophones: 'Wireless microphones',
  CarDashReversingCams: 'Car Dash/Reversing Cams',
  CampingandCooking: 'Camping and Cooking',
  ArduinoModulesShields: 'Arduino Modules & Shields',
  ArduinoBoards: 'Arduino Boards',
  ArduinoSensors: 'Arduino Sensors',
  ArduinoDisplays: 'Arduino Displays',
  MetalDetectors: 'Metal Detectors',
  AVSenders: 'AV Senders',
  SolarChargers: 'Solar Chargers',
  VoiceRecorders: 'Voice Recorders',
  InfraredCameras: 'Infrared Cameras',
  HardDrivesandSSDs: 'Hard Drives and SSDs',
  TVAccessories: 'TV Accessories',
  ActionCameras: 'Action Cameras',
  Monitorbrackets: 'Monitor brackets',
  Spotlights: 'Spotlights',
  Lanterns: 'Lanterns',
  IPWirelessCameras: 'IP & Wireless Cameras',
  NetworkExtenders: 'Network Extenders',
  Megaphones: 'Megaphones',
  ModemsRoutersSwitches: 'Modems, Routers, & Switches',
  Clampmeters: 'Clampmeters',
  WorkLights: 'Work Lights',
  USBFlashdrives: 'USB Flash drives',
  LEDPowerSupplies: 'LED Power Supplies',
  Cars: 'Cars',
  Splitters: 'Splitters',
  OfficeGadgets: 'Office Gadgets',
  NeuronBlocks: 'Neuron Blocks',
  DoorPhones: 'Door Phones',
  Distancemeters: 'Distance meters',
  DomeCameras: 'Dome Cameras',
  PortableSpeakers: 'Portable Speakers',
  LCDLEDDisplayPanels: 'LCD/LED Display Panels',
  HardDriveCasesandDocks: 'Hard Drive Cases and Docks',
  BulletCameras: 'Bullet Cameras',
  DigitalVideoRecorders: 'Digital Video Recorders',
  Measuring: 'Measuring',
  CameraandPhone: 'Camera and Phone',
  STEM: 'STEM',
  Drones: 'Drones',
  UHFVHFTransceivers: 'UHF/VHF Transceivers',
  Invertersandgenerators: 'Inverters and generators',
  PlasticBoxes: 'Plastic Boxes',
  SmartCameras: 'Smart Cameras',
  WebcamsDisplayDevices: 'Webcams & Display Devices',
  Bluetoothspeakersandaudio: 'Bluetooth speakers and audio',
  Protection: 'Protection',
  SecurityMonitors: 'Security Monitors',
  SolarPanelsandpacks: 'Solar Panels and packs',
  Caravanmobilepower: 'Caravan & mobile power',
  Connectors: 'Connectors',
}
