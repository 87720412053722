import React, { useContext } from 'react'
import { Image as ImageType } from 'shared-types'
import {
  ErrorType,
  VISION_STATE,
  VisionSearchRecognitionResult,
} from './visionSearchStore.types'

interface VisionSearchContextType {
  visionSearchOpen: boolean
  setVisionSearchOpen: (visionSearchOpen: boolean) => void

  helpModalActive: boolean
  setHelpModalActive: (helpModalActive: boolean) => void

  visionState: string
  setVisionState: (visionState: VISION_STATE) => void

  isUploading: boolean
  uploadedImage: ImageType

  recognitionResults: Array<VisionSearchRecognitionResult>
  setRecognitionResults: React.Dispatch<
    React.SetStateAction<VisionSearchRecognitionResult[]>
  >

  capturedImageData: string
  setCapturedImage: (imageData: string) => void

  imageUploadProgress: number

  resetVisionSearch: () => void

  // Webcam Captured Image Data
  clearCapturedImage: () => void

  // Image File for Uploading
  setUploadImageFile: (imageFile: File) => void

  // Camera Handling
  cameraActive: boolean
  setCameraActive: (cameraActive: boolean) => void

  // Error Handling
  error: ErrorType
  setError: (error: ErrorType) => void
}

export const defaultVisionSearchContextValue: VisionSearchContextType = {
  visionSearchOpen: false,
  helpModalActive: false,
  visionState: 'start',
  uploadedImage: null,
  imageUploadProgress: 0,
  recognitionResults: [],
  capturedImageData: null,
  cameraActive: false,
  isUploading: false,
  error: {
    active: false,
    notification: 'There was an error. Please try again.',
    statusCode: null,
    error: null,
  },

  setVisionSearchOpen: () => {},
  setHelpModalActive: () => {},
  setCameraActive: () => {},
  setError: () => {},
  resetVisionSearch: () => {},
  setVisionState: () => {},
  clearCapturedImage: () => {},
  setCapturedImage: () => {},
  setUploadImageFile: () => {},
  setRecognitionResults: () => {},
}

export const VisionSearchContext = React.createContext<VisionSearchContextType>(
  defaultVisionSearchContextValue
)

export const useVisionSearch = () => {
  return useContext(VisionSearchContext)
}
