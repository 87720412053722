import {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useScrollLock, VisuallyHidden } from '@overdose/components'
import {
  IconBuildingStore,
  IconCamera,
  IconChevronDown,
  IconMapPin,
  IconMenu2,
  IconPlaylistAdd,
  IconSearch,
  IconShoppingBag,
  IconUser,
} from '@tabler/icons-react'
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Button, LoadingSpinner } from '~/components'
import {
  navigationCardData1,
  navigationCardData2,
  navigationCardData3,
} from '~/components/AccountQuickViewDrawer/AccountQuickViewDrawer.mock'
import { Image } from '~/components/Image'
import Input from '~/components/Input'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import {
  useCart,
  useCartDrawer,
  useCustomer,
  useCustomerListsDrawer,
  useDebounce,
  usePage,
  useSearchResults,
  useUserDeliveryPostcode,
} from '~/hooks'
import { useUserPreferredStore } from '~/hooks/useUserPreferredStore'
import { DEFAULT_COUNTRY_CODE_NZ } from '~/constants/countries'
import { useVisionSearch } from '~/providers/VisionSearch/VisionSearchContext'
import { VisionSearchProvider } from '~/providers/VisionSearch/VisionSearchProvider'
import jaycarLogo from '~/public/images/jaycar-logo.svg'
import rtmLogo from '~/public/images/rtm-logo.svg'
import styles from './Header.module.css'
import { HeaderProps } from './Header.types'

const Badge = dynamic(
  async () => {
    const mod = await import('@overdose/components')
    return mod.Badge
  },
  {
    ssr: false,
    loading: () => {
      return <IconShoppingBag />
    },
  }
)

const SearchResults = dynamic(
  async () => {
    const mod = await import('~/components/SearchResults')
    return mod.SearchResults
  },
  { ssr: false }
)
const VisionSearch = dynamic(
  async () => {
    const mod = await import('../VisionSearch')
    return mod.VisionSearch
  },
  { ssr: false }
)

const CartDrawer = dynamic(
  async () => {
    const mod = await import('~/components/CartDrawer')
    return mod.CartDrawer
  },
  { ssr: false }
)

const MegaMenu = dynamic(
  async () => {
    const mod = await import('~/components/MegaMenu')
    return mod.MegaMenu
  },
  { ssr: false }
)

const AccountQuickViewDrawer = dynamic(
  async () => {
    const mod = await import('~/components/AccountQuickViewDrawer')
    return mod.AccountQuickViewDrawer
  },
  { ssr: false }
)

const headerLinks = {
  loyaltyLink: {
    href: '/account/rewards',
    title: 'View all',
  },
  ordersLink: {
    href: '/account/orders',
    title: 'View all',
  },
  accountsInfoLink: {
    href: '/account/dashboard',
    title: 'View all',
  },
}

const DEBOUNCE_DELAY = 300
const whiteColor = 'var(--color-grayscale-white)'
const iconButtonStyles = {
  '--btn-size-md-height': '32px',
  '--btn-ghost-color-text-primary': whiteColor,
}

const navIconButtonStyles = {
  '--btn-ghost-text-decoration': 'none',
  '--btn-size-lg-padding': 0,
}

const headerDrawerBtnStyles = {
  '--btn-ghost-text-decoration': 'none',
  '--btn-ghost-color-text-primary': whiteColor,
  '--btn-font-weight': 'var(--typography-body-regular-font-weight)',
  '--btn-icon-size': '18',
  '--btn-font-size': 'var(--typography-body-small-font-size)',
  '--btn-icon-spacing': '4px',
  '--btn-size-md-height': '34px',
}

const HeaderComponent: FC<HeaderProps> = ({
  links,
  megaMenu,
  visionSearch,
  width = 'full',
  payLaterProviders,
  homePageH1,
}: HeaderProps) => {
  const { store, promptUserToSelectStore } = useUserPreferredStore()
  const { openDrawer } = useCustomerListsDrawer()
  const { customer } = useCustomer()
  const { primaryNavigation } = usePage()
  const router = useRouter()
  const isHomePage = router?.asPath?.split('#')[0] === '/'
  const [openAccountQuickViewer, setOpenAccountQuickViewer] = useState(false)
  const [openMegaMenu, setOpenMegaMenu] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const { cart } = useCart()
  const debouncedSearchQuery = useDebounce(searchQuery, DEBOUNCE_DELAY)
  const { searchResults, isLoadingSearchResults } = useSearchResults(
    debouncedSearchQuery,
    isOpen
  )
  const { postcode, promptUserPostcodeDrawer, isAuthDrawerOpen } =
    useUserDeliveryPostcode() || {}
  const { setVisionSearchOpen } = useVisionSearch()
  const inputRef = useRef<HTMLInputElement>(null)
  const searchRef = useRef<HTMLInputElement>(null)

  const page = usePage()

  useEffect(() => {
    const listener = (e) => {
      if (!searchRef.current || searchRef.current.contains(e.target)) {
        return
      }
      e.target.className &&
      typeof e.target.className.includes !== 'undefined' &&
      e.target.className.includes('Base-Input')
        ? setIsOpen(e.target.className.includes('Base-Input'))
        : setIsOpen(false)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [])

  useScrollLock(isOpen)

  useEffect(() => {
    if (isAuthDrawerOpen) {
      document.body.classList.add('lock-body-scroll')
    }

    return () => {
      document.body.classList.remove('lock-body-scroll')
    }
  }, [isAuthDrawerOpen])

  const handleAccountViewer = useCallback(() => {
    if (customer) {
      setOpenAccountQuickViewer(true)
    } else {
      router.push({ hash: 'login' })
    }
  }, [customer, router])

  const onSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (searchQuery) {
      router.push(`/search?q=${encodeURIComponent(searchQuery)}`)
    }
  }

  useEffect(() => {
    if (router.isReady && router?.asPath?.includes('search')) {
      setSearchQuery(router.query.q as string)
    }
  }, [router.isReady, router?.asPath, router.query.q])

  useEffect(() => {
    const handleRouteChangeEnd = () => {
      setSearchQuery('')
      setIsOpen(false)
      if (inputRef?.current) {
        inputRef.current.blur()
      }
    }
    router.events.on('routeChangeComplete', handleRouteChangeEnd)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeEnd)
    }
  }, [router.events])

  const { openCartDrawer } = useCartDrawer()

  const renderLogo = () => {
    return (
      <>
        {/* JayCar */}
        <Image
          src={jaycarLogo}
          alt='Jaycar Logo'
          width={148}
          height={40}
          className={classNames('hidden', styles.jaycarLogoDesktop)}
        />
        <Image
          src={jaycarLogo}
          alt='Jaycar Logo'
          width={112}
          height={30}
          className={classNames('hidden', styles.jaycarLogoMobile)}
        />

        {/* RTM */}
        <Image
          src={rtmLogo}
          alt='RTM Logo'
          width={148}
          height={40}
          className={classNames('hidden', styles.rtmLogoDesktop)}
        />
        <Image
          src={rtmLogo}
          alt='RTM Logo'
          width={112}
          height={30}
          className={classNames('hidden', styles.rtmLogoMobile)}
        />
      </>
    )
  }

  const renderSearchBar = (idSuffix: string) => {
    return (
      <form onSubmit={onSearchSubmit} className='grow'>
        <Input
          theme={{
            root: classNames(styles.searchInput, 'px-4 bg-white'),
            input: 'z-10 text-lg leading-6',
          }}
          type='text'
          name={`search_${idSuffix}`}
          placeholder='Search products, brands and more...'
          autoComplete='off'
          prefix={
            <IconSearch size={20} color='var(--color-typography-heading)' />
          }
          value={searchQuery}
          inputref={inputRef}
          onKeyDown={(e) => {
            if (e?.key === 'Escape') {
              e.currentTarget?.blur()
              setIsOpen(false)
            }
          }}
          onChange={(e) => {
            setSearchQuery(e)
          }}
          onFocus={() => {
            setOpenMegaMenu(false)
            setIsOpen(true)
          }}
          suffix={
            process.env.NEXT_PUBLIC_VISION_SEARCH_ENABLED === 'true' && (
              <Button
                size='sm'
                shape='circle'
                variant='ghost'
                icon={<IconCamera color='var(--color-typography-heading)' />}
                iconOnly
                onClick={() => {
                  setOpenMegaMenu(false)
                  setVisionSearchOpen(true)
                }}>
                <VisuallyHidden>Search by image</VisuallyHidden>
              </Button>
            )
          }
        />
      </form>
    )
  }

  const siteWideBanner = useMemo(() => {
    const banner = { title: '', url: '' }

    if (typeof window !== 'undefined') {
      banner.title =
        "Seen it cheaper? We'll beat it. Learn more about our Price Beat Promise."
      banner.url =
        page.countryCode === DEFAULT_COUNTRY_CODE_NZ
          ? 'https://help.jaycar.co.nz/hc/en-us/articles/28497790683673-Price-Beat-Promise'
          : 'https://help.jaycar.com.au/hc/en-us/articles/28497300179353-Price-Beat-Promise'

      return banner
    }

    if (page.brand !== 'jc' || page.countryCode === DEFAULT_COUNTRY_CODE_NZ) {
      return null
    }

    if (!router.isReady) {
      return banner
    }

    banner.url = `${banner.url}${router.asPath.split('#')[0]}`
    return banner
  }, [router, page])

  useEffect(() => {
    let scrollTimerId: number
    const headerEl = document.querySelector('header')
    const pdpHeaderEl: HTMLDivElement = document.querySelector('.pdp-header')

    const handleScroll = () => {
      if (
        (window.innerWidth > Number('1024') &&
          window.innerHeight > Number('500')) ||
        screen.orientation.type.includes('portrait')
      ) {
        return
      }

      if (scrollTimerId) {
        clearTimeout(scrollTimerId)
      }

      const isNZ = page.countryCode === DEFAULT_COUNTRY_CODE_NZ

      scrollTimerId = window.setTimeout(() => {
        headerEl.style.removeProperty('opacity')
        pdpHeaderEl?.style.removeProperty('top')
      }, Number('500'))

      const offset = isNZ ? Number('172') : Number('204')

      if (window.scrollY > offset) {
        headerEl.style.setProperty('opacity', '0')
        pdpHeaderEl?.style.setProperty('top', '0')
        return
      }
      headerEl.style.removeProperty('opacity')
      pdpHeaderEl?.style.removeProperty('top')
    }

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [page?.countryCode])

  const [oldWebSiteLinkRect, setOldWebSiteLinkRect] = useState<DOMRect>(null)
  useEffect(() => {
    const websiteLink = document.querySelector('.siteWideBanner')

    if (websiteLink) {
      const handleScroll = () => {
        setOldWebSiteLinkRect(websiteLink.getBoundingClientRect())
      }

      handleScroll()
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }

    return () => {}
  }, [])

  return (
    <SectionContainer
      width={width}
      wrapperClassName={classNames(styles.header, {
        '!z-[1002]': isOpen,
        '!top-0': page.countryCode === DEFAULT_COUNTRY_CODE_NZ,
      })}
      tag='header'>
      {siteWideBanner?.url && (
        <div className='bg-background-thumb p-1 text-center siteWideBanner'>
          <p>
            <a
              href={siteWideBanner.url}
              className='text-sm underline text-primary-heading'>
              {siteWideBanner.title}
            </a>
          </p>
        </div>
      )}
      <div
        className={classNames(
          styles.headerBackground,
          styles.headerBorder,
          'flex justify-between lg:hidden'
        )}>
        <Button
          variant='ghost'
          style={headerDrawerBtnStyles}
          icon={<IconBuildingStore size={18} className='mr-1' />}
          onClick={() => {
            setOpenMegaMenu(false)
            promptUserToSelectStore()
          }}>
          {store?.displayName || 'Select Store'}
          <IconChevronDown size={14} />
        </Button>
        <Button
          variant='ghost'
          style={headerDrawerBtnStyles}
          icon={<IconMapPin size={18} />}
          onClick={() => {
            setOpenMegaMenu(false)
            promptUserPostcodeDrawer()
          }}>
          {postcode || 'Postcode'}
          <IconChevronDown size={14} />
        </Button>
      </div>
      <div className={classNames(styles.simpleHeader, styles.headerBackground)}>
        <div className='flex items-center justify-between gap-x-10'>
          <div className={classNames(styles.logoWrapper, 'gap-6')}>
            <div className='lg:hidden block'>
              <Button
                style={iconButtonStyles}
                variant='ghost'
                title='Main Menu'
                shape='circle'
                icon={<IconMenu2 />}
                iconOnly
                onClick={() => {
                  return setOpenMegaMenu((prev) => {
                    return !prev
                  })
                }}>
                <VisuallyHidden>Main menu</VisuallyHidden>
              </Button>
            </div>

            {isHomePage && (
              <h1>
                {renderLogo()}
                <VisuallyHidden>{homePageH1}</VisuallyHidden>
              </h1>
            )}

            {!isHomePage && (
              <Link title='Go to home page' href='/'>
                {renderLogo()}
                <VisuallyHidden>Jaycar</VisuallyHidden>
              </Link>
            )}
            <div className='hidden lg:block grow'>
              {renderSearchBar('desktop')}
            </div>
          </div>
          <div
            className={classNames(
              styles.iconWrapper,
              'text-white flex gap-2.5 lg:gap-6 2xl:gap-8'
            )}>
            <Button
              icon={<IconUser size={32} />}
              iconOnly
              onClick={() => {
                setOpenMegaMenu(false)
                handleAccountViewer()
              }}
              shape='circle'
              style={{
                '--btn-icon-size': '32px',
                ...iconButtonStyles,
              }}
              variant='ghost'>
              <VisuallyHidden>Account</VisuallyHidden>
            </Button>

            <Button
              icon={<IconPlaylistAdd size={32} />}
              iconOnly
              onClick={() => {
                setOpenMegaMenu(false)
                if (customer) {
                  openDrawer('')
                } else {
                  router.push({ hash: 'login' })
                }
              }}
              shape='circle'
              style={{
                '--btn-icon-size': '32px',
                ...iconButtonStyles,
              }}
              variant='ghost'>
              <VisuallyHidden>List</VisuallyHidden>
            </Button>

            <Button
              data-id='viewCartButton'
              icon={
                <Badge
                  count={cart?.totalLineItemQuantity}
                  variant='count'
                  isRounded
                  theme={{ root: styles.countBadge, number: 'w-6 h-6' }}>
                  <IconShoppingBag size={32} />
                </Badge>
              }
              iconOnly
              onClick={() => {
                setOpenMegaMenu(false)
                openCartDrawer()
              }}
              shape='circle'
              style={{
                '--btn-icon-size': '32px',
                ...iconButtonStyles,
              }}
              variant='ghost'>
              <VisuallyHidden>Cart</VisuallyHidden>
            </Button>
          </div>
        </div>
        <div className='lg:!hidden grow mt-2'>{renderSearchBar('mobile')}</div>
      </div>

      <nav className={styles.menuHeader}>
        <div className='flex items-center gap-9'>
          <Button
            style={navIconButtonStyles}
            size='lg'
            variant='ghost'
            icon={<IconMenu2 />}
            onClick={() => {
              return setOpenMegaMenu(true)
            }}>
            Shop Products
          </Button>

          <div className='hidden xl:block border-r border-[var(--header-color-separator)] h-6' />
          {links?.length > 0 &&
            links.map((link) => {
              return (
                <Link
                  href={link.href}
                  key={link.title}
                  className='py-4 hidden xl:block'
                  title={`View ${link.title}`}>
                  <Typography
                    tag={TypographyTag.p}
                    variant={TypographyVariant.BodyLargeBold}
                    className='text-primary-heading'>
                    {link.title}
                  </Typography>
                  <VisuallyHidden>View {link.title}</VisuallyHidden>
                </Link>
              )
            })}
        </div>
        <div className='flex gap-9'>
          <Button
            style={navIconButtonStyles}
            size='lg'
            variant='ghost'
            icon={<IconMapPin />}
            onClick={() => {
              setOpenMegaMenu(false)
              promptUserPostcodeDrawer()
            }}>
            <span className='flex flex-col items-start'>
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodySmall}>
                Deliver to
              </Typography>
              {postcode || 'Postcode'}
            </span>
          </Button>
          <Button
            style={navIconButtonStyles}
            size='lg'
            variant='ghost'
            icon={<IconBuildingStore />}
            onClick={() => {
              setOpenMegaMenu(false)
              promptUserToSelectStore()
            }}>
            <span className='flex flex-col items-start'>
              <Typography
                tag={TypographyTag.span}
                variant={TypographyVariant.BodySmall}>
                Your store
              </Typography>
              {store?.displayName || 'Select Store'}
            </span>
          </Button>
        </div>
      </nav>

      {isOpen && (
        <>
          <div
            className={classNames(
              'absolute left-0 bg-white w-full z-10 @container search-result-container top-[10.5rem]',
              !siteWideBanner?.url ? 'lg:!top-20' : 'lg:!top-28'
            )}
            style={{
              top: `${
                Number('168') +
                (oldWebSiteLinkRect ? Number('33') + oldWebSiteLinkRect.top : 0)
              }px`,
            }}
            ref={searchRef}>
            {isLoadingSearchResults && (
              <div className='absolute inset-0 flex items-center justify-center z-20 backdrop-blur'>
                <LoadingSpinner />
              </div>
            )}
            <SearchResults
              {...searchResults}
              debouncedSearchQuery={debouncedSearchQuery}
              isOpen={isOpen}
            />
          </div>
          <div className={styles.backdrop} />
        </>
      )}

      <AccountQuickViewDrawer
        open={openAccountQuickViewer}
        onClose={() => {
          setOpenAccountQuickViewer(false)
        }}
        navData={[
          navigationCardData1,
          navigationCardData2,
          navigationCardData3,
        ]}
        headerLinks={headerLinks}
      />
      <CartDrawer payLaterProviders={payLaterProviders} />
      <MegaMenu
        isOpen={openMegaMenu}
        categories={primaryNavigation}
        {...megaMenu}
        onClose={() => {
          return setOpenMegaMenu(false)
        }}
      />
      <VisionSearch {...visionSearch} />
    </SectionContainer>
  )
}

export const Header: FC<HeaderProps> = (props) => {
  return (
    <VisionSearchProvider>
      <HeaderComponent {...props} />
    </VisionSearchProvider>
  )
}
