import { IconInfoCircle, IconBuildingStore } from '@tabler/icons-react'
import dynamic from 'next/dynamic'
import Card from '~/components/Card/Card'
import { ProductClickAndCollectStatusCardProps } from '~/components/ProductClickAndCollectStatusCard/ProductClickAndCollectStatusCard.types'
import Status from '~/components/Status/Status'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { useUserPreferredStore } from '~/hooks/useUserPreferredStore'

const ProductClickAndCollectStatusCardTooltip = dynamic(
  async () => {
    const mod = await import('./ProductClickAndCollectStatusCardTooltip')
    return mod.ProductClickAndCollectStatusCardTooltip
  },
  {
    loading: () => {
      return (
        <span className='z-[11] relative inline-block cursor-pointer'>
          <IconInfoCircle
            size={20}
            color='var(--product-status-tooltip-color)'
            className='inline'
          />
        </span>
      )
    },
    ssr: false,
  }
)

export const ProductClickAndCollectStatusCard = ({
  store,
  clickAndCollect,
  inStore,
  sku,
}: ProductClickAndCollectStatusCardProps) => {
  const { promptUserToSelectStore, store: mySelectedStore } =
    useUserPreferredStore() || {}

  return (
    <Card
      title={
        store ? (
          <>
            <Typography
              tag={TypographyTag.span}
              variant={TypographyVariant.BodyRegularBold}
              className='mr-2'>
              {store?.name}
            </Typography>
            {store?.name && (
              <ProductClickAndCollectStatusCardTooltip {...mySelectedStore} />
            )}
          </>
        ) : (
          <Typography
            tag={TypographyTag.span}
            variant={TypographyVariant.BodyRegularBold}
            className='mr-2'>
            Store
          </Typography>
        )
      }
      variant='default'
      icon={<IconBuildingStore size={24} />}>
      <div className='pl-9'>
        {store ? (
          <>
            <Status
              label={
                <>
                  Click & Collect -{' '}
                  {clickAndCollect === 'available'
                    ? 'Available'
                    : 'Not Available'}
                </>
              }
              status={clickAndCollect === 'available' ? 'success' : 'error'}
            />
            <Status
              label={
                <Typography
                  tag={TypographyTag.p}
                  variant={TypographyVariant.BodyRegular}>
                  <span className='leading-6'>
                    Store -{' '}
                    {inStore === 'available' ? 'Available' : 'Not Available'}
                  </span>
                </Typography>
              }
              status={inStore === 'available' ? 'success' : 'error'}
            />
          </>
        ) : (
          <Typography
            tag={TypographyTag.div}
            variant={TypographyVariant.BodyRegular}>
            Choose a store for stock availability
          </Typography>
        )}
      </div>
      <button
        type='button'
        className='absolute inset-0 bg-transparent border-0 outline-0 z-10 w-full'
        aria-label='Choose a store for stock availability'
        onClick={() => {
          return promptUserToSelectStore(sku)
        }}
      />
    </Card>
  )
}
